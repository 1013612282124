// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { postHostSearch } from '@/services/decoration.js'

// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
// import moment from 'moment'
// import el from 'element-ui/src/locale/lang/el'

export default {
  name: 'hotSearchAdd',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
    },
    handleChange (data, prop, item, cdata) {
      // if (prop === 'couponable_type') {
      //   if (data === 'ShopItem') {
      //     this.btnTxt = this.$t('button.next')
      //   } else {
      //     this.btnTxt = this.$t('button.confirm')
      //   }
      // }
    },
    formSubmit (data) {
      console.log('---formSubmit-----', data)
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      const params = {
        search_word: {
          ...obj
        }
      }
      console.log(obj)

      postHostSearch(params).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'hotSearchList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    },
    afterAdd (creatData, alterData, prop) {
    },
    removeArray (createData, obj) {
    },
    checkDisable (createData) {
    },
    handleSwitchChange (value, createDate, prop) {
    }
  }
}
